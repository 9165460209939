import {
  Checkbox,
  IOption,
  SelectDropdown,
  TextField,
} from "@patientmpower/spiro";
import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { Form, Formik, FormikErrors, FormikProps, FormikTouched } from "formik";
import { forwardRef } from "react";
import { Handle, Position } from "reactflow";
import * as yup from "yup";

import { IMeasurementTypeOptions } from "../../../../../../@types/Measurements";
import {
  IWorkflowsSelectOption,
  IWorkflowRule,
  IRuleCondition,
} from "../../../../../../@types/Worflows";
import { ArrowDown } from "../../../../../../assets/icons/ArrowDown";
import { ErrorMessage, selectCss } from "../../WorkflowsBuilder.styles";
import {
  Container,
  Title,
  BottomContainer,
  TrueOrFalseBox,
  SelectContainer,
  TextFieldCss,
  ExcludeBlowsContainer,
  popupOptionsCss,
} from "./RuleComponent.styles";

interface IRuleComponentBaseProps {
  ruleTypes?: IWorkflowsSelectOption[];
  ruleConditionComparators?: IWorkflowsSelectOption[];
  ruleConditionTriggers?: IWorkflowsSelectOption[];
  measurementTypes?: IMeasurementTypeOptions[];
}

interface IRuleComponentFormikProps extends IRuleComponentBaseProps {
  content?: IWorkflowRule;
}

interface IRuleComponentProps extends IRuleComponentBaseProps {
  formik: FormikProps<IWorkflowRule>;
}

function RuleComponent({
  ruleTypes,
  ruleConditionComparators,
  ruleConditionTriggers,
  measurementTypes,
  formik,
}: IRuleComponentProps) {
  const shouldShowErrorMessage = (
    field: "ruleType" | `conditions[${number}].${keyof IRuleCondition}`,
    index?: number
  ): string => {
    if (field.startsWith("conditions") && index !== undefined) {
      const key = field.split(".")[1] as keyof IRuleCondition;

      const touchedArray = formik.touched.conditions as
        | FormikTouched<IRuleCondition>[]
        | undefined;
      const errorArray = formik.errors.conditions as
        | FormikErrors<IRuleCondition>[]
        | undefined;

      if (touchedArray && errorArray) {
        const touched = touchedArray[index];
        const error = errorArray[index];

        return touched?.[key] && error?.[key] ? (error[key] as string) : "";
      }

      return "";
    }

    return formik.touched[field as keyof typeof formik.touched] &&
      formik.errors[field as keyof typeof formik.errors]
      ? (formik.errors[field as keyof typeof formik.errors] as string)
      : "";
  };

  const handleMeasurementTypesOptions = () => {
    if (measurementTypes?.length === 0) return [];

    const categoryOrder = ["spirometry", "measurement", "lab_result"];

    const groupedData = measurementTypes?.reduce(
      (accumulator: any, measurementType: IMeasurementTypeOptions) => {
        if (!accumulator[measurementType.category]) {
          accumulator[measurementType.category] = {
            label: measurementType.categoryDisplayName,
            title: measurementType.category,
            options: [],
          };
        }

        accumulator[measurementType.category]?.options?.push({
          label: measurementType.measurementTypeDisplayName,
          value: measurementType.measurementType,
        });

        return accumulator;
      },
      {}
    );

    const orderedData = categoryOrder?.map((category) => {
      if (groupedData[category]) {
        groupedData[category]?.options?.sort((a: IOption, b: IOption) =>
          a.label.localeCompare(b.label)
        );
      }
      return groupedData[category];
    }) as DefaultOptionType[];

    return Object.values(orderedData) ?? [];
  };

  const handleRuleTriggerOptions = () => {
    const measurementTypeCategory = measurementTypes?.find(
      (measurementType) =>
        measurementType.measurementType ===
        formik.values.conditions[0].measurementType
    )?.category;

    if (measurementTypeCategory === "spirometry") {
      return (
        ruleConditionTriggers?.map((ruleTrigger) => {
          return {
            label: ruleTrigger.name,
            value: ruleTrigger.type,
            key: ruleTrigger.type,
          };
        }) ?? []
      );
    }

    return (
      ruleConditionTriggers
        ?.map((ruleTrigger) => {
          return {
            label: ruleTrigger.name,
            value: ruleTrigger.type,
            key: ruleTrigger.type,
          };
        })
        .filter((ruleTrigger) => ruleTrigger.key !== "HighestInSession") ?? []
    );
  };

  const handleThresholdName = () => {
    if (
      formik.values.conditions[0].conditionComparatorType ===
      "PercentageDecreaseFromBaseline"
    ) {
      return "Percentage decrease";
    }

    if (
      formik.values.conditions[0].conditionComparatorType ===
      "PercentageIncreaseFromBaseline"
    ) {
      return "Percentage increase";
    }

    return "Threshold";
  };

  const handleThresholdUnit = () => {
    if (
      formik.values.conditions[0].conditionComparatorType ===
        "PercentageDecreaseFromBaseline" ||
      formik.values.conditions[0].conditionComparatorType ===
        "PercentageIncreaseFromBaseline"
    )
      return "(%)";

    const unit = measurementTypes?.find(
      (measurementType) =>
        measurementType.measurementType ===
        formik.values.conditions[0].measurementType
    )?.unit;

    return unit ? `(${unit})` : null;
  };

  return (
    <Container>
      <Title>RULE</Title>

      <SelectContainer isError={shouldShowErrorMessage("ruleType") !== ""}>
        <p>Type</p>
        <SelectDropdown
          placeholder="Measurement"
          value={formik.values.ruleType}
          options={
            ruleTypes?.map((ruleType) => {
              return {
                label: ruleType.name,
                value: ruleType.type,
                key: ruleType.type,
              };
            }) ?? []
          }
          onValueChange={(value) => {
            formik.resetForm();
            formik.setFieldValue("ruleType", value as string);
          }}
          className={`${selectCss()} nopan nodrag`}
        />
        <ErrorMessage>{shouldShowErrorMessage("ruleType")}</ErrorMessage>
      </SelectContainer>

      {formik.values.ruleType !== undefined ? (
        <SelectContainer
          isError={
            shouldShowErrorMessage("conditions[0].measurementType") !== ""
          }
        >
          <p>Measurement type</p>
          <Select
            placeholder="FVC"
            defaultValue={formik.values.measurementType}
            suffixIcon={<ArrowDown />}
            options={handleMeasurementTypesOptions()}
            popupClassName={popupOptionsCss()}
            notFoundContent="No data available, please check your wards"
            className={`${selectCss()} nopan nodrag`}
            onChange={(value) => {
              formik.setFieldValue("conditions[0].measurementType", value);

              formik.setFieldValue("ruleTrigger", undefined);
            }}
          />

          <ErrorMessage>
            {shouldShowErrorMessage("conditions[0].measurementType")}
          </ErrorMessage>
        </SelectContainer>
      ) : null}

      {formik.values.conditions[0].measurementType !== undefined ? (
        <SelectContainer
          isError={
            shouldShowErrorMessage(
              "conditions[0].conditionComparatorType",
              0
            ) !== ""
          }
        >
          <p>Comparator</p>
          <SelectDropdown
            placeholder="Greater than"
            value={formik.values.conditions[0].conditionComparatorType}
            options={
              ruleConditionComparators?.map((ruleComparator) => {
                return {
                  label: ruleComparator.name,
                  value: ruleComparator.type,
                  key: ruleComparator.type,
                };
              }) ?? []
            }
            onValueChange={(value) =>
              formik.setFieldValue(
                "conditions[0].conditionComparatorType",
                value as string
              )
            }
            className={`${selectCss()} nopan nodrag`}
          />
          <ErrorMessage>
            {shouldShowErrorMessage("conditions[0].conditionComparatorType", 0)}
          </ErrorMessage>
        </SelectContainer>
      ) : null}

      {formik.values.conditions[0].conditionComparatorType !== undefined ? (
        <>
          <SelectContainer
            isError={
              shouldShowErrorMessage("conditions[0].threshold", 0) !== ""
            }
          >
            <p>
              {handleThresholdName()} {handleThresholdUnit()}
            </p>
            <TextField
              name="conditions[0].threshold"
              placeholder="0"
              value={formik.values.conditions[0].threshold}
              label=""
              onChange={formik.handleChange}
              backgroudColor="white"
              className={`${TextFieldCss()} nopan nodrag`}
              type="number"
            />
            <ErrorMessage>
              {shouldShowErrorMessage("conditions[0].threshold", 0)}
            </ErrorMessage>
          </SelectContainer>

          <SelectContainer
            isError={
              shouldShowErrorMessage("conditions[0].triggerType", 0) !== ""
            }
          >
            <p>Rule trigger</p>
            <SelectDropdown
              placeholder="Any reading"
              value={formik.values.conditions[0].triggerType}
              options={handleRuleTriggerOptions()}
              onValueChange={(value) =>
                formik.setFieldValue(
                  "conditions[0].triggerType",
                  value as string
                )
              }
              className={`${selectCss()} nopan nodrag`}
            />
            <ErrorMessage>
              {shouldShowErrorMessage("conditions[0].triggerType", 0)}
            </ErrorMessage>
          </SelectContainer>

          {measurementTypes?.find(
            (x) =>
              x.measurementType === formik.values.conditions[0].measurementType
          )?.category === "spirometry" ? (
            <ExcludeBlowsContainer>
              <Checkbox
                checked={formik.values.conditions[0].excludeBadBlows ?? false}
                onChange={(value) =>
                  formik.setFieldValue("conditions[0].excludeBadBlows", value)
                }
                label=""
              />
              <p>Only include blows that are of usable or acceptable quality</p>
            </ExcludeBlowsContainer>
          ) : null}
        </>
      ) : null}

      <BottomContainer>
        <TrueOrFalseBox>True</TrueOrFalseBox>
        <TrueOrFalseBox isRed>False</TrueOrFalseBox>
      </BottomContainer>
    </Container>
  );
}

const RuleComponentFormik = forwardRef<
  FormikProps<IWorkflowRule>,
  IRuleComponentFormikProps
>(
  (
    {
      ruleTypes,
      ruleConditionComparators,
      ruleConditionTriggers,
      measurementTypes,
      content,
    },
    ref
  ) => {
    const handleOnSubmitForm = () => {};

    const ruleValidationSchema = yup.object().shape({
      id: yup.string(),
      ruleType: yup.string().required("Type is required"),
      conditions: yup
        .array()
        .of(
          yup.object().shape({
            measurementType: yup
              .string()
              .required("Measurement type is required"),
            conditionComparatorType: yup
              .string()
              .required("Comparator is required"),
            threshold: yup
              .number()
              .moreThan(0, "Threshold must be greater than 0")
              .required("Threshold is required"),
            triggerType: yup.string().required("Rule trigger is required"),
            excludeBadBlows: yup.boolean(),
          })
        )
        .min(1, "At least one condition is required"),
    });

    return (
      <Formik<IWorkflowRule>
        innerRef={ref}
        initialValues={{
          ruleType: content?.ruleType,
          measurementType: content?.conditions[0].measurementType,
          conditions: [
            {
              measurementType: content?.conditions[0].measurementType,
              conditionComparatorType:
                content?.conditions[0].conditionComparatorType,
              threshold: content?.conditions[0].threshold,
              triggerType: content?.conditions[0].triggerType,
              excludeBadBlows: content?.conditions[0].excludeBadBlows,
            },
          ],
        }}
        onSubmit={handleOnSubmitForm}
        validationSchema={ruleValidationSchema}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <RuleComponent
              formik={formik}
              ruleTypes={ruleTypes}
              ruleConditionComparators={ruleConditionComparators}
              ruleConditionTriggers={ruleConditionTriggers}
              measurementTypes={measurementTypes}
            />
          </Form>
        )}
      </Formik>
    );
  }
);

export function RuleNode(props: any) {
  const { data } = props;

  return (
    <>
      {!data.isFirstStep ? (
        <Handle id="target" type="target" position={Position.Top} />
      ) : null}
      <RuleComponentFormik
        ruleTypes={data.ruleTypes}
        ruleConditionComparators={data.ruleConditionComparators}
        ruleConditionTriggers={data.ruleConditionTriggers}
        measurementTypes={data.measurementTypeOptions}
        content={data.content}
        ref={data.ref}
      />
      <Handle
        type="source"
        id="true"
        position={Position.Bottom}
        style={{ left: "86px" }}
      />
      <Handle
        type="source"
        id="false"
        position={Position.Bottom}
        style={{ left: "240px" }}
        isConnectable
      />
    </>
  );
}
