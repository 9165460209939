import { css } from "@patientmpower/spiro";
import dayjs from "dayjs";
import {
  XAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  ReferenceLine,
} from "recharts";

import { IPatientBaseline } from "../../@types/Baselines";
import { CustomChartLegend } from "../CustomChartLegend/CustomChartLegend";
import { CustomRechartsDot } from "../CustomRechartsDot/CustomRechartsDot";
import { CustomRechartsTooltip } from "../CustomRechartsTooltip/CustomRechartsTooltip";
import {
  ChartTitle,
  StyledTile,
  StyledSubtitle,
  EmptyStateOverlay,
} from "./ChartCard.styles";
import { ChartCardValue, ChartData, ChartConfig } from "./ChartCard.types";
import { ChartCardLoading } from "./ChartCardLoading";
import { ChartSpecificValues } from "./ChartSpecificValues/ChartSpecificValues";

export type ChartCardProps = {
  title: string;
  data: Map<string, ChartData[]> | Map<string, any[]>;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  isLoading?: boolean;
  chartConfig: ChartConfig[];
  specificValues: ChartCardValue[];
  patientBaselines: IPatientBaseline[];
};

export function ChartCard({
  data,
  title,
  onClick,
  isLoading,
  chartConfig,
  specificValues,
  patientBaselines,
}: ChartCardProps) {
  const isEmptyState = !data || !data.size;

  const chartCss = css({
    ".recharts-surface": {
      marginTop: "12px",
    },
  });

  const mergedDataSources = !isEmptyState
    ? Array.from(data.values()).flat()
    : [];

  return (
    <StyledTile onClick={onClick}>
      {isLoading ? (
        <ChartCardLoading />
      ) : (
        <>
          <ChartTitle>{title}</ChartTitle>

          {isEmptyState && (
            <StyledSubtitle>
              {title === "-" ? "No data to show" : "No data for selected dates"}
            </StyledSubtitle>
          )}
          {isEmptyState && <EmptyStateOverlay />}

          <ResponsiveContainer
            width="100%"
            height={isEmptyState ? "57%" : "67%"}
          >
            <ScatterChart
              data={mergedDataSources}
              style={{ cursor: "pointer" }}
              className={chartCss()}
            >
              {!isEmptyState && (
                <Legend
                  height={80}
                  verticalAlign="top"
                  content={
                    <CustomChartLegend baseline={patientBaselines.length > 0} />
                  }
                />
              )}
              <Tooltip
                cursor={false}
                content={<CustomRechartsTooltip data={mergedDataSources} />}
                wrapperStyle={{ outline: "none" }}
                labelFormatter={() => undefined}
              />
              <XAxis
                hide
                dataKey="unformattedTime"
                domain={[
                  dayjs().add(-2, "month").valueOf(),
                  dayjs().add(7, "day").valueOf(),
                ]}
                type="number"
                allowDuplicatedCategory={false}
              />

              {patientBaselines &&
                patientBaselines.map((patientBaseline) => {
                  const handleEndSegmentXAxis = () => {
                    if (patientBaseline.archivedDate !== undefined) {
                      return dayjs(patientBaseline.archivedDate).valueOf();
                    }

                    const archiveDateDayjs = dayjs(
                      patientBaseline.archivedDate
                    );

                    if (dayjs().diff(archiveDateDayjs) < 7) {
                      return dayjs().add(7, "day").valueOf();
                    }

                    return dayjs().valueOf();
                  };

                  return (
                    <ReferenceLine
                      key={`baseline-ref-${patientBaseline.id}`}
                      stroke="#58C488"
                      strokeDasharray="10 8"
                      strokeWidth={2}
                      segment={[
                        {
                          x: dayjs(patientBaseline.created).valueOf(),
                          y: patientBaseline.baseline,
                        },
                        {
                          x: handleEndSegmentXAxis(),
                          y: patientBaseline.baseline,
                        },
                      ]}
                      ifOverflow="hidden"
                    />
                  );
                })}

              {!isEmptyState &&
                chartConfig.map(
                  (currentChartConfig: ChartConfig, index: number) => {
                    const {
                      yKey,
                      stroke,
                      dotShape,
                      color,
                      lineOpacity,
                      source,
                    } = currentChartConfig;

                    const dataSource = source
                      ? data.get(`${yKey}|${source}`)
                      : [];

                    return (
                      <Scatter
                        key={`${yKey}_${source}_${index}`}
                        isAnimationActive={false}
                        fill={color}
                        line={{
                          stroke: color,
                          strokeWidth: stroke || 1.4,
                          opacity: lineOpacity,
                        }}
                        lineJointType="monotoneX"
                        shape={
                          <CustomRechartsDot fill={color} shape={dotShape} />
                        }
                        dataKey={yKey}
                        name={source}
                        data={dataSource}
                      />
                    );
                  }
                )}
            </ScatterChart>
          </ResponsiveContainer>
          <ChartSpecificValues values={specificValues} />
        </>
      )}
    </StyledTile>
  );
}
