import { SliderInput } from "@patientmpower/spiro";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";

import {
  IQuestionDisplayProps,
  ISurveyAnswer,
} from "../../../../../../../@types/Surveys";
import { useDynamicsurveys } from "../../../../../../../hooks/useDynamicSurveys";
import {
  Title,
  Description,
} from "../TextQuestionDisplay/TextQuestionDisplay.styles";
import {
  HorizontalSliderContainer,
  verticalSliderCss,
  VerticalSliderContainer,
  LabelsRow,
  SliderContainer,
  LabelContainerLeft,
  LabelContainerRight,
  SliderValueHorizontal,
  SliderLabelHorizontal,
  SliderLabelVertical,
  SliderValueVertical,
  horizontalSliderCss,
  VerticalSliderInputContainer,
  FormAnswerField,
  verticalSliderCssNoTooltip,
  horizontalSliderCssNoTooltip,
  SliderUsabilityMessage,
} from "./SliderQuestionDisplay.styles";

interface ISliderProps extends IQuestionDisplayProps {
  verticalSliderHeight?: number | string;
}

export function SliderQuestionDisplay({
  title,
  description,
  options,
  step,
  orientation,
  questionId,
  verticalSliderHeight,
}: ISliderProps) {
  const [value, setValue] = useState<number>();
  const [renderSlider, setRenderSlider] = useState<boolean>(false);
  const sliderRef = useRef<HTMLDivElement>(null);

  const sliderFormik = useFormik({
    initialValues: {
      answer: 0,
    },
    onSubmit: () => {},
  });

  const { surveyAnswerArray, updateSurveyAnswerArray } = useDynamicsurveys();

  function checkSliderAnswer(): boolean {
    const questionAnswer = surveyAnswerArray.find(
      (answer) => answer.surveyQuestionId === questionId
    );

    return questionAnswer?.answerValue !== "";
  }

  const updateAnswerArrayAndFormik = (value: number) => {
    const answerUpdate: ISurveyAnswer = {
      surveyQuestionId: questionId ?? "",
      answerValue: value.toString(),
    };
    updateSurveyAnswerArray(answerUpdate);
    sliderFormik.setFieldValue("answer", value);
  };

  const displayTooltipOnFirstTouch = () => {
    if (options && value === options[0].value && !checkSliderAnswer()) {
      updateAnswerArrayAndFormik(options[0].value);
    }
  };

  const mobileTouchHandler = (event: TouchEvent) => {
    const firstTouch = event.changedTouches[0];
    let type = "";
    switch (event.type) {
      case "touchstart":
        type = "mousedown";
        break;
      case "touchmove":
        type = "mousemove";
        break;
      case "touchend":
        type = "mouseup";
        break;
      default:
        return;
    }

    const simulatedEvent = new MouseEvent(type, {
      bubbles: true,
      cancelable: false,
      clientX: firstTouch.clientX,
      clientY: firstTouch.clientY,
    });

    firstTouch.target.dispatchEvent(simulatedEvent);
    event.preventDefault();
  };

  useEffect(() => {
    const storedAnswer = surveyAnswerArray.find(
      (surveyAnswer) => surveyAnswer.surveyQuestionId === questionId
    );
    if (storedAnswer !== undefined) {
      const newValue = parseFloat(storedAnswer.answerValue);
      if (!Number.isNaN(newValue)) {
        setValue(newValue);
        updateAnswerArrayAndFormik(newValue);
      } else if (options) {
        setValue(options[0].value);
      }
    }
    if (sliderRef.current) {
      sliderRef.current.addEventListener(
        "touchstart",
        mobileTouchHandler,
        true
      );
      sliderRef.current.addEventListener("touchmove", mobileTouchHandler, true);
      sliderRef.current.addEventListener("touchend", mobileTouchHandler, true);
    }
    setRenderSlider(true);

    return () => {
      if (sliderRef.current) {
        sliderRef.current.removeEventListener("touchstart", mobileTouchHandler);
        sliderRef.current.removeEventListener("touchmove", mobileTouchHandler);
        sliderRef.current.removeEventListener("touchend", mobileTouchHandler);
      }
    };
  }, []);

  const sliderUsabilityMessage = () => {
    return (
      <SliderUsabilityMessage>
        Please swipe the slider above to begin
      </SliderUsabilityMessage>
    );
  };

  if (options !== undefined && orientation !== undefined) {
    return (
      <FormAnswerField ref={sliderRef} onClick={displayTooltipOnFirstTouch}>
        <Title> {title}</Title>
        <Description>{description}</Description>
        {orientation === "Horizontal" && renderSlider && (
          <HorizontalSliderContainer>
            <SliderContainer>
              <SliderInput
                className={
                  checkSliderAnswer()
                    ? horizontalSliderCss()
                    : horizontalSliderCssNoTooltip()
                }
                valueVisible
                onValueChange={(value) => {
                  setValue(value);
                  updateAnswerArrayAndFormik(value);
                }}
                min={options[0].value}
                max={options[1].value}
                step={step}
                defaultValue={value}
              />
            </SliderContainer>
            <LabelsRow>
              <LabelContainerLeft>
                <SliderValueHorizontal>
                  {options[0].value}
                </SliderValueHorizontal>
                <SliderLabelHorizontal>
                  {options[0].label}
                </SliderLabelHorizontal>
              </LabelContainerLeft>
              <LabelContainerRight>
                <SliderValueHorizontal>
                  {options[1].value}
                </SliderValueHorizontal>
                <SliderLabelHorizontal>
                  {options[1].label}
                </SliderLabelHorizontal>
              </LabelContainerRight>
            </LabelsRow>
            {sliderUsabilityMessage()}
          </HorizontalSliderContainer>
        )}
        {orientation === "Vertical" && renderSlider && (
          <VerticalSliderContainer style={{ height: verticalSliderHeight }}>
            <SliderValueVertical>{options[1].value}</SliderValueVertical>
            <SliderLabelVertical>{options[1].label}</SliderLabelVertical>
            <VerticalSliderInputContainer>
              <SliderInput
                valueVisible
                onValueChange={(value) => {
                  setValue(value);
                  updateAnswerArrayAndFormik(value);
                }}
                min={options[0].value}
                max={options[1].value}
                step={step}
                vertical
                className={
                  checkSliderAnswer()
                    ? verticalSliderCss()
                    : verticalSliderCssNoTooltip()
                }
                defaultValue={value}
              />
            </VerticalSliderInputContainer>
            <SliderValueVertical>{options[0].value}</SliderValueVertical>
            <SliderLabelVertical>{options[0].label}</SliderLabelVertical>
            {sliderUsabilityMessage()}
          </VerticalSliderContainer>
        )}
      </FormAnswerField>
    );
  }
  return null;
}
