import dayjs from "dayjs";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
  IPatientBaseline,
  IPatientBaselineData,
} from "../../../../@types/Baselines";
import { MeasurementTypes } from "../../../../@types/Measurements";
import { ChartCard } from "../../../../components/ChartCard";
import { ChartConfig } from "../../../../components/ChartCard/ChartCard.types";
import { useMultipleMeasurements } from "../../../../hooks/queries/measurements";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { getSourceLegends } from "../../../../utils/chartSourceUtils";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { MeasurementModal } from "../MeasurementModal";
import { MeasurementModalMobile } from "../MeasurementModalMobile";

type MeasurementCardProps = {
  measurementType: MeasurementTypes;
  cardTitle: string;
  unit?: string;
  isSpirometry?: boolean;
  minValue?: number;
  maxValue?: number;
  checkNewData?: (mostRecentDate: dayjs.Dayjs) => void;
};

export function MeasurementCard({
  cardTitle,
  measurementType,
  unit,
  isSpirometry = false,
  minValue,
  maxValue,
  checkNewData,
}: MeasurementCardProps) {
  if (measurementType === "bp_diastolic") {
    // Because we are now bringing all of the blood pressure measurements from one single graph, we are ignoring the sytolic and only caring about the diastolic since it will get the data for both
    return null;
  }

  const { openModal } = useModal();
  const { patientId } = useParams();
  const { isMobile } = useIsMobile();

  const [searchParams] = useSearchParams();

  const chartConfigs: ChartConfig[] = [];
  const allMeasurements: Map<string, any[]> = new Map<string, any[]>();
  const allPatientBaselines: Map<string, IPatientBaselineData> = new Map<
    string,
    IPatientBaselineData
  >();
  let mainValues: any = { min: 0, max: 0, average: 0 };

  const measurementTypes: MeasurementTypes[] = [measurementType];
  if (measurementType === "bp_systolic") {
    measurementTypes.push("bp_diastolic");
  }

  const { data, isInitialLoading } = useMultipleMeasurements({
    types: measurementTypes,
    patientId,
  });

  if (data && data.length > 0) {
    data.forEach((d: any, index: number) => {
      if (!d) return;

      const { type, measurements, mainMeasurementValues, patientBaselines } = d;

      const activeBaseline = (patientBaselines as IPatientBaseline[])?.find(
        (x: IPatientBaseline) => x.archived === false
      )?.baseline;

      allPatientBaselines.set(type, {
        activeBaseline,
        baselines: patientBaselines,
      });

      if (d.mainMeasurementValues.maxDate && checkNewData) {
        checkNewData(d.mainMeasurementValues.maxDate);
      }

      if (measurements && measurements.size > 0) {
        measurements.forEach((measurementsMap: any, key: string) => {
          const source = (key || "unknown").toLowerCase();
          const sourceLegend = getSourceLegends(type, source);

          if (measurementType === "bp_systolic" && source === "apple-health") {
            return;
          }

          allMeasurements.set(`${type}|${source}`, measurementsMap);
          chartConfigs.push({
            dot: sourceLegend.shape !== "line",
            dotShape: sourceLegend.shape,
            lineType: "monotone",
            yKey: type,
            color: sourceLegend.color,
            lineOpacity: sourceLegend.lineOpacity,
            min: minValue,
            max: maxValue,
            source,
          });

          if (index === 0) mainValues = mainMeasurementValues;
        });
      }
    });
  }

  const onClickToOpenMeasurement = () => {
    mixpanelActions.track(`User Action: Opened ${measurementType} graph`);
    if (isMobile) {
      openModal(
        <MeasurementModalMobile
          title={cardTitle}
          patientId={patientId}
          minValue={minValue}
          maxValue={maxValue}
          measurementType={measurementType}
        />,
        { width: "100 vw", height: "100vh", showCloseButton: true }
      );
    } else {
      openModal(
        <MeasurementModal
          patientId={patientId}
          cardTitle={cardTitle}
          unit={unit}
          measurementType={measurementType}
          minValue={minValue}
          maxValue={maxValue}
          isSpirometry={isSpirometry}
        />,
        {
          width: "auto",
          height: "676px",
          showCloseButton: true,
          padding: "0px",
        }
      );
    }
  };

  const handleCardClick = () => {
    onClickToOpenMeasurement();
  };

  useEffect(() => {
    const type = searchParams.get("type");

    if (type === measurementType) {
      handleCardClick();
    }
  }, [searchParams]);

  return (
    <div style={{ padding: isMobile ? "5px" : undefined }}>
      <ChartCard
        title={cardTitle}
        data={
          (allMeasurements as Map<string, any[]>) || new Map<string, any[]>()
        }
        chartConfig={chartConfigs}
        isLoading={isInitialLoading}
        onClick={handleCardClick}
        specificValues={
          measurementType === "bp_systolic"
            ? []
            : [
                {
                  label: "Highest",
                  value: mainValues?.max != null ? mainValues.max : "-",
                },
                {
                  label: "Mean",
                  value: mainValues?.average != null ? mainValues.average : "-",
                },
                {
                  label: "Lowest",
                  value: mainValues?.min != null ? mainValues.min : "-",
                },
              ]
        }
        patientBaselines={
          allPatientBaselines.get(measurementType)?.baselines ?? []
        }
      />
    </div>
  );
}
