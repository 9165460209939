import { Button, SelectDropdown } from "@patientmpower/spiro";
import { Spin } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FormikProps, useFormik } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrop } from "react-dnd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ConnectionLineType,
  Edge,
  Connection,
  useReactFlow,
  XYPosition,
  NodeChange,
} from "reactflow";
import "reactflow/dist/style.css";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import { IMeasurementTypeOptions } from "../../../../@types/Measurements";
import { Notification } from "../../../../@types/Notification";
import {
  IWorkflow,
  IWorkflowResponse,
  IWorkflowRule,
  IWorkflowSettings,
  IWorkflowsSurveyOption,
  IWorkflowStep,
  IWorkflowVersion,
} from "../../../../@types/Worflows";
import { ToastNotification } from "../../../../components/ToastNotification";
import { NOTIFICATION } from "../../../../constants/localStorageKeys";
import { useHospitalGroupWards } from "../../../../hooks/queries/wards";
import { useWorkflowsSelectOptions } from "../../../../hooks/queries/workflows";
import { useModal } from "../../../../hooks/useModal";
import { measurementsService } from "../../../../services/measurementsService";
import { workflowsService } from "../../../../services/worflowsService";
import { getLongFormattedDate } from "../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { ActionButtons } from "./components/ActionButtons";
import { NodeLibrary } from "./components/NodesLibrary";
import { ResponseNode } from "./components/ResponseComponent";
import { RuleNode } from "./components/RuleComponent";
import { SectionButtonComponent } from "./components/SectionButton";
import { TextFieldRow } from "./components/TextFieldRow";
import { WorkflowStatusChangeModal } from "./components/WorkflowStatusChangeModal";
import {
  ErrorMessage,
  Header,
  ReactFlowContainer,
  Row,
  SectionsContainer,
  SectionsHeader,
  SettingsChildContainer,
  SettingsContainer,
  SettingsLabel,
  spinCss,
  wardsSelectCss,
  WorkflowsBuilderContainer,
} from "./WorkflowsBuilder.styles";

export function WorkflowsBuilder() {
  const navigate = useNavigate();
  const { screenToFlowPosition, fitView } = useReactFlow();
  const { openModal, closeModal } = useModal();
  const { workflowId } = useParams();
  const [searchParams] = useSearchParams();
  const { hospitalGroupWards, isWardsFetching, isWardsRefetching } =
    useHospitalGroupWards(
      parseInt(searchParams.get("hospitalGroupId") ?? "", 10)
    );
  const {
    ruleTypes,
    ruleConditionComparators,
    ruleConditionTriggers,
    responseTypes,
    responseTimes,
    actionTypes,
    isFetching,
    isRefetching,
  } = useWorkflowsSelectOptions();

  const [workflow, setWorkflow] = useState<IWorkflow>();
  const [selectedSection, setSelectedSection] = useState<"flow" | "settings">(
    workflowId ? "flow" : "settings"
  );
  const [measurementTypeOptions, setMeasurementTypeOptions] = useState<
    IMeasurementTypeOptions[]
  >([]);
  const [surveyOptions, setSurveyOptions] = useState<IWorkflowsSurveyOption[]>(
    []
  );
  const [firstStepId, setFirstStepId] = useState(uuidv4());
  const [nodes, setNodesState, onNodesChange] = useNodesState([]);
  const [edges, setEdgesState, onEdgesChange] = useEdgesState([]);
  const [recentWardsRequested, setRecentWardsRequested] = useState<number[]>(
    []
  );
  const [whiteButtonIsLoading, setWhiteButtonIsLoading] = useState(false);
  const [purpleButtonIsLoading, setPurpleButtonIsLoading] = useState(false);
  const [areOptionsLoading, setAreOptionsLoading] = useState(false);
  const [isWorkflowLoading, setIsWorkflowLoading] = useState(false);
  const [hasSettingsChanged, setHasSettingsChanged] = useState(false);
  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: "max-content",
    rightMargin: "50%",
  });

  const containerRef = useRef<HTMLDivElement>(null);

  const isLoading =
    isFetching ||
    isRefetching ||
    areOptionsLoading ||
    isWorkflowLoading ||
    isWardsFetching ||
    isWardsRefetching;

  const formValidationSchema = yup.object().shape({
    title: yup.string().max(50).required("Title is required."),
    description: yup.string().required("Description is required"),
    wards: yup.array().min(1, "Ward is required."),
  });

  const formik = useFormik<IWorkflowSettings>({
    initialValues: {
      title: "",
      description: "",
      wards: [] as number[],
      published: false,
    },
    onSubmit: () => {},
    validationSchema: formValidationSchema,
  });

  const shouldShowErrorMessage = (field: "title" | "description" | "wards") => {
    return formik?.touched[field] ? formik?.errors[field] ?? "" : "";
  };

  // Handlers

  const handleCancel = () => {
    mixpanelActions.track("User Action: Cancel workflow");
    navigate("/workflows");
  };

  const setLoadingState = (isLoading: boolean) => {
    setWhiteButtonIsLoading(isLoading);
    setPurpleButtonIsLoading(isLoading);
  };

  const handleSaveSettings = async (isPublished?: boolean) => {
    if (!workflow) return;

    const workflowSettings: IWorkflowSettings = {
      title: formik.values.title,
      description: formik.values.description,
      wards: formik.values.wards,
      published: isPublished ?? workflow.published,
    };

    const result = await workflowsService.updateWorkflow(
      workflow.id,
      workflowSettings
    );

    const notification: Notification = {
      show: true,
      message: "",
      type: "success",
      width: "max-content",
    };

    if (result.status >= 200 && result.status < 300) {
      notification.message = "Settings updated successfully";

      setWorkflow((prevWorkflow) => {
        if (prevWorkflow) {
          return {
            ...prevWorkflow,
            title: formik.values.title,
            description: formik.values.description,
            wards: formik.values.wards,
            published: workflow.published,
          };
        }

        return prevWorkflow;
      });
    } else {
      notification.message =
        "Something went wrong while updating settings, please try again";
      notification.type = "error";
    }

    setHasSettingsChanged(false);
    setNotification(notification);

    setTimeout(() => {
      setNotification({
        show: false,
        message: "notificationMessage",
        type: "error",
        width: undefined,
        rightMargin: "50%",
      });
    }, 3500);
  };

  const handleOnSaveWorkflow = async (isPublished: boolean) => {
    const createStepsFromNodes = () => {
      return nodes?.reduce((acc, node) => {
        const formikValues = node.data.ref?.current.values;

        const step: IWorkflowStep = {
          id: uuidv4(),
          positionX: node.position.x,
          positionY: node.position.y,
          stepType: formikValues.ruleType ? "Rule" : "Response",
          ruleType: formikValues.ruleType,
          conditions: formikValues.conditions,
          responseType: formikValues.responseType,
          timeType: formikValues.responseTime,
          time: (formikValues.timeUtc as Dayjs)?.format("HH:mm"),
          messageText: formikValues.messageText,
          questionText: formikValues.questionText,
          option1Text: formikValues.option1Text,
          option2Text: formikValues.option2Text,
          actionType: formikValues.actionType,
          surveyId: formikValues.surveyId,
          pushNotificationMessage: formikValues.pushNotificationMessage,
          measurementType: formikValues.ruleType
            ? formikValues.conditions[0].measurementType
            : formikValues.measurementType,
        };

        acc[node.id] = step;
        return acc;
      }, {} as { [id: string]: IWorkflowStep });
    };

    const assignEdgesToSteps = (steps: { [id: string]: IWorkflowStep }) => {
      const { ...tempSteps } = steps;

      edges.forEach((edge) => {
        const { source, target, sourceHandle } = edge;

        if (sourceHandle === "true") {
          tempSteps[source].nextStepOne = tempSteps[target];
        } else if (sourceHandle === "false") {
          tempSteps[source].nextStepTwo = tempSteps[target];
        }
      });

      return tempSteps[firstStepId];
    };

    setLoadingState(true);

    const workflowId = workflow?.id ?? uuidv4();
    const steps = createStepsFromNodes();
    const firstStep = assignEdgesToSteps(steps);

    const workflowVersion: IWorkflowVersion = {
      id: uuidv4(),
      firstStep,
      workflowId,
    };

    const workflowToAdd: IWorkflow = {
      id: workflowId,
      title: formik.values.title,
      description: formik.values.description,
      versions: [workflowVersion],
      wards: formik.values.wards,
      published: isPublished,
    };

    const notification: Notification = {
      show: true,
      message: "",
      type: "success",
      width: "max-content",
    };

    if (workflow) {
      if (hasSettingsChanged || isPublished !== workflow?.published) {
        await handleSaveSettings(isPublished);
      }

      notification.message = `Workflow successfully ${
        isPublished ? "published" : "unpublished"
      }`;

      const addVersionResult = await workflowsService.addWorkflowVersion(
        workflow.id,
        workflowVersion
      );

      if (addVersionResult.status >= 200 && addVersionResult.status < 300) {
        notification.message = `Workflow changes successfully ${
          isPublished ? "published" : "saved"
        }`;
      } else {
        notification.message = `Error ${
          isPublished ? "publishing" : "saving"
        } workflow changes, please try again`;
        notification.type = "error";
      }
    } else {
      const addWorkflowResult = await workflowsService.addWorkflow(
        workflowToAdd
      );

      if (addWorkflowResult.status >= 200 && addWorkflowResult.status < 300) {
        notification.message = `Workflow has been ${
          isPublished ? "published" : "saved"
        } successfully`;
      } else {
        notification.message = `Error ${
          isPublished ? "publishing" : "saving"
        } workflow, please try again`;
        notification.type = "error";
      }
    }

    localStorage.setItem(NOTIFICATION, JSON.stringify(notification));

    setLoadingState(false);
    navigate("/workflows");
  };

  const onConnect = useCallback(
    (conn: Connection) => {
      const hasSourceConnection = edges.some(
        (edge) =>
          edge.source === conn.source && edge.sourceHandle === conn.sourceHandle
      );
      const hasTargetConnection = edges.some(
        (edge) =>
          edge.target === conn.target && edge.targetHandle === conn.targetHandle
      );

      if (!hasSourceConnection && !hasTargetConnection) {
        setEdgesState((eds) => addEdge({ ...conn, type: "step" }, eds));
      }
    },
    [edges]
  );

  const handleNodesChanges = (changes: NodeChange[]) => {
    const filteredChanges = changes.filter(
      (node) => !(node.type === "remove" && node.id === firstStepId)
    );

    onNodesChange(filteredChanges);
  };

  const handleSurveyAndWardOptions = async (
    wards: number[],
    nodesToUpdate?: any[]
  ) => {
    if (
      recentWardsRequested === wards ||
      wards.length === 0 ||
      (wards === workflow?.wards &&
        (surveyOptions.length > 0 || measurementTypeOptions.length > 0))
    ) {
      return;
    }

    setRecentWardsRequested(wards);
    setAreOptionsLoading(true);

    const surveys = (await workflowsService.getWorflowsSurveyOptions(wards))
      .data;
    const measurementTypes = (
      await measurementsService.getMeasurementTypesByWards(wards, true)
    ).data;

    setMeasurementTypeOptions(measurementTypes);
    setSurveyOptions(surveys);

    if (nodes.length === 0 && !nodesToUpdate) {
      setAreOptionsLoading(false);
      return;
    }

    const tempNodes = nodesToUpdate ?? nodes;

    const updatedNodes = tempNodes?.map((node) => {
      if (node.type === "responseNode") {
        const surveyIndex = surveys?.findIndex(
          (survey) =>
            survey.surveyId === node.data.ref?.current?.values.surveyId
        );

        if (surveyIndex === -1) {
          node.data.ref?.current?.setFieldValue("surveyId", undefined);
        }
      }

      const measurementTypeIndex = measurementTypes?.findIndex(
        (measurementType) =>
          measurementType.measurementType ===
          node.data.ref?.current?.values.measurementType
      );

      if (measurementTypeIndex === -1) {
        node.data.ref?.current?.setFieldValue("measurementType", undefined);
      }

      return {
        ...node,
        data: {
          ...node.data,
          measurementTypeOptions: measurementTypes,
          surveyOptions: surveys,
        },
      };
    });

    setNodesState(updatedNodes);
    setAreOptionsLoading(false);
  };

  // Handle Nodes

  const createNode = (
    step: IWorkflowStep,
    formikRef:
      | React.RefObject<FormikProps<IWorkflowRule>>
      | React.RefObject<FormikProps<IWorkflowResponse>>,
    content: IWorkflowRule | IWorkflowResponse,
    type: string
  ) => {
    const newNode: any = {
      id: step.id,
      type,
      position: { x: step.positionX || 0, y: step.positionY || 0 },
      data: {
        ruleTypes,
        ruleConditionComparators,
        ruleConditionTriggers,
        responseTypes,
        responseTimes,
        actionTypes,
        surveyOptions,
        measurementTypeOptions,
        ref: formikRef,
        isFirstStep: step.id === firstStepId,
        content,
      },
    };

    setNodesState((prevNodes: IWorkflowStep[]) => [...prevNodes, newNode]);
  };

  const addEdgeToState = (
    sourceId: string,
    targetId: string,
    sourceHandle: string
  ) => {
    const edge: Edge = {
      id: `edge-${sourceId}-${targetId}`,
      source: sourceId,
      target: targetId,
      sourceHandle,
    };
    setEdgesState((eds) => addEdge({ ...edge, type: "step" }, eds));
  };

  const addStepNodeAndEdge = (step: IWorkflowStep) => {
    if (!step || !step.id) return;

    if (step.stepType === "Rule") {
      const formikRef = React.createRef<FormikProps<IWorkflowRule>>();
      const ruleNode: IWorkflowRule = {
        id: step.id,
        ruleType: step.ruleType,
        measurementType: step.measurementType,
        conditions: step.conditions ?? [],
      };

      createNode(step, formikRef, ruleNode, "ruleNode");
    } else if (step.stepType === "Response") {
      const formikRef = React.createRef<FormikProps<IWorkflowResponse>>();
      const today = dayjs().format("YYYY-MM-DD");

      const responseNode: IWorkflowResponse = {
        id: step.id,
        measurementType: step.measurementType,
        responseType: step.responseType,
        actionType: step.actionType,
        surveyId: step.surveyId,
        responseTime: step.timeType,
        timeUtc: step.time ? dayjs(`${today} ${step.time}`) : undefined,
        messageText: step.messageText,
        questionText: step.questionText,
        option1Text: step.option1Text,
        option2Text: step.option2Text,
      };

      createNode(step, formikRef, responseNode, "responseNode");
    }

    if (step.nextStepOne && step.nextStepOneId) {
      addEdgeToState(step.id, step.nextStepOneId, "true");
      addStepNodeAndEdge(step.nextStepOne);
    }

    if (step.nextStepTwo && step.nextStepTwoId) {
      addEdgeToState(step.id, step.nextStepTwoId, "false");
      addStepNodeAndEdge(step.nextStepTwo);
    }
  };

  const handleWorkflowNodes = async (workflow: IWorkflow) => {
    if (!workflow) return;

    const latestVersion = workflow.versions[workflow.versions.length - 1];
    const { firstStep, firstStepId } = latestVersion;

    if (firstStep && firstStepId) {
      setFirstStepId(firstStepId);
      addStepNodeAndEdge(firstStep);
    }

    setNodesState((prevNodes) => {
      handleSurveyAndWardOptions(workflow.wards, prevNodes);
      return prevNodes;
    });
  };

  const [, drop] = useDrop({
    accept: "NODE",
    drop: (item: any, monitor) => {
      const offset = monitor.getClientOffset();
      const { x, y } = offset ?? { x: 0, y: 0 };

      const position = screenToFlowPosition({
        x,
        y,
      });

      const formikRef =
        React.createRef<FormikProps<IWorkflowRule | IWorkflowResponse>>();

      const newNode = {
        id: uuidv4(),
        type: item.type,
        position,
        data: {
          ruleTypes,
          ruleConditionComparators,
          ruleConditionTriggers,
          responseTypes,
          responseTimes,
          actionTypes,
          surveyOptions,
          measurementTypeOptions,
          ref: formikRef,
        },
      };

      setNodesState((prevNodes: any) => [...prevNodes, newNode]);
    },
  });

  const nodeTypes = useMemo(
    () => ({ ruleNode: RuleNode, responseNode: ResponseNode }),
    []
  );

  const wardsDropdown = useMemo(() => {
    return (
      <SelectDropdown
        placeholder="Wards"
        multiple
        value={formik.values.wards?.map(String)}
        options={
          hospitalGroupWards?.map((ward) => {
            return {
              label: ward.hospitalName,
              value: ward.hospitalId.toString(),
              key: uuidv4(),
            };
          }) ?? []
        }
        onValueChange={(value) => {
          formik.setFieldValue("wards", (value as string[])?.map(Number));
        }}
        className={`${wardsSelectCss()} nopan nodrag`}
      />
    );
  }, [hospitalGroupWards, formik.values.wards]);

  const handleFlowSectionClick = async () => {
    setSelectedSection("flow");

    await handleSurveyAndWardOptions(formik.values.wards);

    mixpanelActions.track(
      `UserAction: FlowTab${workflow?.title ? `[${workflow.title}]` : ""}`
    );

    setTimeout(() => {
      fitView({ duration: 1000 });
    }, 10);
  };

  useEffect(() => {
    if (
      workflowId !== undefined &&
      !workflow &&
      !isFetching &&
      !areOptionsLoading
    ) {
      setIsWorkflowLoading(true);

      workflowsService.getWorkflowById(workflowId).then((workflow) => {
        setWorkflow(workflow.data);

        handleWorkflowNodes(workflow.data).then(() => {
          formik.setValues({
            title: workflow.data.title,
            description: workflow.data.description,
            wards: workflow.data.wards,
            published: workflow.data.published,
          });

          setIsWorkflowLoading(false);
        });
      });
    }
  }, [workflowId, isFetching, isRefetching, areOptionsLoading]);

  useEffect(() => {
    if (!workflowId && !isFetching && !isRefetching && nodes.length === 0) {
      const formikRef =
        React.createRef<FormikProps<IWorkflowRule | IWorkflowResponse>>();

      const newNode = {
        id: firstStepId,
        type: "ruleNode",
        position: { x: 0, y: 0 } as XYPosition,
        data: {
          ruleTypes,
          ruleConditionComparators,
          ruleConditionTriggers,
          responseTypes,
          responseTimes,
          actionTypes,
          surveyOptions,
          measurementTypeOptions,
          ref: formikRef,
          isFirstStep: true,
        },
      };

      setNodesState((prevNodes: any) => [...prevNodes, newNode]);
    }
  }, [workflowId, isFetching, isRefetching, areOptionsLoading]);

  useEffect(() => {
    if (!workflow) return;

    if (
      formik.values.title.trim() !== workflow.title ||
      formik.values.description.trim() !== workflow.description ||
      formik.values.wards !== workflow.wards
    ) {
      setHasSettingsChanged(true);
    } else {
      setHasSettingsChanged(false);
    }
  }, [formik.values]);

  const handleActionButtonsClick = async (
    isPublished: boolean,
    isSave?: boolean
  ) => {
    const validateNodes = async () => {
      const validations = await Promise.all(
        nodes?.map(async (node: any) => {
          const formikRef = node.data.ref?.current;
          if (formikRef) {
            formikRef.submitForm();
            const errors = await formikRef.validateForm();
            return Object.keys(errors).length === 0;
          }
          return false;
        })
      );
      return validations.every(Boolean);
    };

    const handleOnModalClose = async (
      notification?: Notification,
      refetchTable?: boolean,
      saveWorkflow?: boolean
    ) => {
      closeModal();

      if (saveWorkflow) {
        await handleOnSaveWorkflow(isPublished);
      }
    };

    const areNodesValid = await validateNodes();
    await formik.submitForm();
    const settingsFormErrors = await formik.validateForm();
    const isSettingsFormValid = Object.keys(settingsFormErrors).length === 0;

    if (!areNodesValid || !isSettingsFormValid) {
      await handleSurveyAndWardOptions(formik.values.wards);

      setLoadingState(false);
      setSelectedSection(isSettingsFormValid ? "flow" : "settings");
      return;
    }

    if (isSave) {
      await handleOnSaveWorkflow(isPublished);
      return;
    }

    openModal(
      <WorkflowStatusChangeModal
        workflowId={workflowId}
        published={isPublished}
        onClose={handleOnModalClose}
      />,
      {
        width: "623px",
        height: "100%",
        showCloseButton: true,
      }
    );
  };

  return (
    <WorkflowsBuilderContainer>
      {selectedSection === "flow" && isLoading ? (
        <Spin className={spinCss()} fullscreen size="large" />
      ) : (
        <>
          <Header>
            {workflow && (
              <p>
                * Last edited{" "}
                {getLongFormattedDate(workflow.updated || workflow.created)}
              </p>
            )}
            <ActionButtons
              workflow={workflow}
              handleOnSaveWorkflow={handleActionButtonsClick}
              purpleButtonIsLoading={purpleButtonIsLoading}
              whiteButtonIsLoading={whiteButtonIsLoading}
              handleCancel={handleCancel}
            />
          </Header>

          <SectionsHeader>
            <SectionsContainer>
              <SectionButtonComponent
                isSelected={selectedSection === "flow"}
                onClick={handleFlowSectionClick}
                label="Flow"
              />
              <SectionButtonComponent
                isSelected={selectedSection === "settings"}
                onClick={() => {
                  mixpanelActions.track("UserAction: SettingsTab");
                  setSelectedSection("settings");
                }}
                label="Settings"
              />
            </SectionsContainer>
          </SectionsHeader>
        </>
      )}

      <ReactFlowContainer
        style={{ display: selectedSection === "flow" ? "flex" : "none" }}
      >
        <NodeLibrary />
        <div ref={drop} style={{ width: "100%", height: "100%" }}>
          <ReactFlow
            ref={containerRef}
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            maxZoom={1}
            minZoom={0.3}
            style={{ width: "100%", height: "100%" }}
            deleteKeyCode={["Delete", "Backspace"]}
            onNodesChange={handleNodesChanges}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            connectionLineType={ConnectionLineType.SmoothStep}
            fitView
            fitViewOptions={{ duration: 1000 }}
          >
            <Background />
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowContainer>

      <SettingsContainer
        style={{ display: selectedSection === "settings" ? "block" : "none" }}
      >
        <SettingsChildContainer>
          <TextFieldRow
            label="Title"
            name="title"
            placeholder="Workflow title"
            value={formik.values.title}
            onChange={formik.handleChange}
            shouldShowErrorMessage={shouldShowErrorMessage}
          />
          <TextFieldRow
            label="Description"
            name="description"
            placeholder="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            shouldShowErrorMessage={shouldShowErrorMessage}
          />
          <Row isError={shouldShowErrorMessage("wards") !== ""}>
            <SettingsLabel>Wards</SettingsLabel>
            {wardsDropdown}
            <ErrorMessage isSettingsPageError>
              {shouldShowErrorMessage("wards")}
            </ErrorMessage>
          </Row>

          {workflowId ? (
            <Row isSaveSettings>
              <Button
                type="button"
                label="Save settings"
                onClick={handleSaveSettings}
                disabled={!hasSettingsChanged}
              />
            </Row>
          ) : null}
        </SettingsChildContainer>
      </SettingsContainer>

      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
          rightMargin="25%"
        />
      ) : null}
    </WorkflowsBuilderContainer>
  );
}
