import {
  DatePickerInput,
  OptionButton,
  OptionsBar,
  RadioButton,
  Checkbox,
  TextField,
} from "@patientmpower/spiro";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

import { IAddBaseline } from "../../../../../../@types/Baselines";
import { Notification } from "../../../../../../@types/Notification";
import { Calendar } from "../../../../../../assets/icons/Calendar";
import { CircleX } from "../../../../../../assets/icons/CircleX";
import { GraphToggle } from "../../../../../../assets/icons/GraphToggle";
import { TableToggle } from "../../../../../../assets/icons/TableToggle";
import { spirometryMeasurementsValuesFilters } from "../../../../../../constants/measurements";
import { baselinesService } from "../../../../../../services/baselinesService";
import { userService } from "../../../../../../services/userService";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import { ModalOptions } from "../OptionsPopover/Options.types";
import { ArchiveBaselineModal } from "./components/ArchiveBaselineModal";
import { ConfirmBaselineChangeModal } from "./components/ConfirmBaselineChangeModal";
import {
  OptionDivider,
  DatepickerContainer,
  FilterContainer,
  RadioGroupContainer,
  RadioButtonContainer,
  OptionsBarContainer,
  SvgCss,
  BaselineContainer,
  BaselineInputCss,
  BaselineInputContainer,
  SaveContainer,
  BaselineButton,
  ClearButton,
  OptionButtonContainer,
} from "./CustomOptionsBar.styles";
import { dateFilterOptions } from "./dateFilterOptions";

type CustomOptionsBarType = {
  cardTitle: string;
  onChange: (selectedDateFilter: DateFilterOptionType) => void;
  selectedFilterDate: DateFilterOptionType;
  initialOptions: ModalOptions;
  initialDisplay: string;
  initialScaleYAxis: boolean;
  onChangeOption: (options: ModalOptions) => void;
  onChangeScaleYAxis: (scaleYAxisboolean: boolean) => void;
  onDisplayChange: (filter: string) => void;
  onTableViewChange: (enableMeasurementsTable: boolean) => void;
  measurementType: string;
  mobile?: boolean;
  baseline?: number;
  patientId?: string;
  onBaselineChange?: (notification: Notification) => void;
  unit?: string;
};

export type DateFilterOptionType = {
  label: string;
  endDate: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
};

export function CustomOptionsBar({
  cardTitle,
  onChange,
  selectedFilterDate,
  initialOptions,
  initialDisplay,
  initialScaleYAxis,
  onChangeScaleYAxis,
  onChangeOption,
  onTableViewChange,
  onDisplayChange,
  onBaselineChange,
  measurementType,
  mobile = false,
  baseline,
  patientId,
  unit,
}: CustomOptionsBarType) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isBaselineOpen, setIsBaselineOpen] = useState(false);
  const [
    isConfirmBaselineChangeModalOpen,
    setIsConfirmBaselineChangeModalOpen,
  ] = useState(false);
  const [isArchiveBaselineModalOpen, setIsArchiveBaselineModalOpen] =
    useState(false);
  const [checked, setChecked] = useState(initialDisplay);

  const [options, setOptions] = useState({
    hideFvcRejected: initialOptions?.hideFvcRejected || false,
    hideFev1Rejected: initialOptions?.hideFev1Rejected || false,
    hideFvcUsable: initialOptions?.hideFvcUsable || false,
    hideFev1Usable: initialOptions?.hideFev1Usable || false,
  });
  const [filterCount, setFilterCount] = useState(0);
  const [showMeasurementsTable, setShowMeasurementsTable] = useState(false);

  const [baselineValue, setBaselineValue] = useState<string>(
    baseline?.toFixed(1) ?? ""
  );
  const [rerender, setRerender] = useState(false);

  const cardTitleId = cardTitle.replaceAll(" ", "_");
  const customDateFiterOption = "custom";
  const wrapperRef = useRef<any>(null);
  const filterWrapperRef = useRef<any>(null);
  const baselineWrapperRef = useRef<any>(null);
  const calendarButtonRef = useRef<any>(null);
  const filterButtonRef = useRef<any>(null);
  const baselineButtonRef = useRef<any>(null);
  let dateOptions: any[] = [];

  const isFirstRender = useRef(true);

  if (mobile) {
    dateOptions = [];
  } else {
    dateOptions = dateFilterOptions;
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        calendarOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !calendarButtonRef.current.contains(event.target)
      ) {
        setCalendarOpen(false);
      }

      if (
        filterOpen &&
        filterWrapperRef.current &&
        !filterWrapperRef.current.contains(event.target) &&
        !filterButtonRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }

      if (
        isBaselineOpen &&
        baselineWrapperRef.current &&
        !baselineWrapperRef.current.contains(event.target) &&
        !baselineButtonRef.current.contains(event.target)
      ) {
        if (!isArchiveBaselineModalOpen && !isConfirmBaselineChangeModalOpen) {
          setBaselineValue(baseline?.toFixed(1) ?? "");
        }

        setIsBaselineOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [calendarOpen, filterOpen, isBaselineOpen]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }

    onChangeOption(options);
  }, [options]);

  useEffect(() => {
    onDisplayChange(checked);
  }, [checked]);

  useEffect(() => {
    let count = 0;
    if (checked === "BestOfDay") {
      count += 1;
    }
    // Check for spirometry filters based on measurement types
    if (measurementType === "fvc") {
      if (options.hideFvcRejected) {
        count += 1;
      }
      if (options.hideFvcUsable) {
        count += 1;
      }
    } else if (measurementType === "fev1") {
      if (options.hideFev1Rejected) {
        count += 1;
      } else if (options.hideFev1Usable) {
        count += 1;
      }
    } else if (measurementType === "pef") {
      if (options.hideFvcRejected) {
        count += 1;
      }
      if (options.hideFvcUsable) {
        count += 1;
      }
      if (options.hideFev1Rejected) {
        count += 1;
      }
      if (options.hideFev1Usable) {
        count += 1;
      }
    }

    setFilterCount(count);
  }, [checked, options]);

  const handleOnChangeDateWithDatePicker = (dateRange: any) => {
    if (dateRange.startDate && dateRange.endDate) {
      setCalendarOpen(false);
    } else {
      return;
    }

    onChange({
      label: customDateFiterOption,
      startDate: dayjs(dateRange.startDate),
      endDate: dayjs(dateRange.endDate),
    });
  };

  const handleQualityFilterChangeRejected = (
    isChecked: boolean,
    type?: string
  ) => {
    if (measurementType === "fvc" || type === "fvc") {
      mixpanelActions.track(
        `UserAction: ${isChecked ? "Hide" : "Unhide"} FVC Rejected`
      );

      setOptions((prevState) => ({
        ...prevState,
        hideFvcRejected: isChecked,
      }));
    } else if (measurementType === "fev1" || type === "fev1") {
      mixpanelActions.track(
        `UserAction: ${isChecked ? "Hide" : "Unhide"} FEV1 Rejected`
      );

      setOptions((prevState) => ({
        ...prevState,
        hideFev1Rejected: isChecked,
      }));
    }
  };

  const handleQualityFilterChangeUsable = (
    isChecked: boolean,
    type?: string
  ) => {
    if (measurementType === "fvc" || type === "fvc") {
      mixpanelActions.track(
        `UserAction: ${isChecked ? "Hide" : "Unhide"} FVC Usable`
      );

      setOptions((prevState) => ({
        ...prevState,
        hideFvcUsable: isChecked,
      }));
    } else if (measurementType === "fev1" || type === "fev1") {
      mixpanelActions.track(
        `UserAction: ${isChecked ? "Hide" : "Unhide"} FEV1 Usable`
      );

      setOptions((prevState) => ({
        ...prevState,
        hideFev1Usable: isChecked,
      }));
    }
  };

  const handleScaleYAxisChange = () => {
    mixpanelActions.track(`User Action: ScaleYAxis Button`);
    onChangeScaleYAxis(!initialScaleYAxis);
  };

  const handleClick = (filter: string) => {
    if (checked !== filter) {
      setChecked(filter);

      userService.updateUserDataDisplayPreference(filter).then(() => {
        if (filter === "BestOfDay") {
          mixpanelActions.track(`User action: DataDisplayBestOfTheDay`);
        } else {
          mixpanelActions.track(`User action: DataDisplayAlldataPoints`);
        }
      });
    }
  };

  const showHideToolbar = () => {
    if (spirometryMeasurementsValuesFilters.indexOf(measurementType) !== -1)
      return true;
    return false;
  };

  const getFilterCountText = () => {
    if (filterCount === 0) {
      return "Filter";
    }
    return `Filter (${filterCount})`;
  };

  const handlePEFQualityHideFilters = () => {
    return (
      <>
        <RadioGroupContainer>
          <b>FVC quality hide</b>
          <Checkbox
            checked={options.hideFvcRejected}
            onChange={(isChecked) =>
              handleQualityFilterChangeRejected(isChecked, "fvc")
            }
            label="Rejected"
            id="fvc-rejected-checkbox"
            labelPosition="before"
            spacing="88px"
          />
          <Checkbox
            checked={options.hideFvcUsable}
            onChange={(isChecked) =>
              handleQualityFilterChangeUsable(isChecked, "fvc")
            }
            label="Usable"
            id="fvc-usable-checkbox"
            labelPosition="before"
            spacing="96px"
          />
        </RadioGroupContainer>
        <RadioGroupContainer>
          <b>FEV1 quality hide</b>
          <Checkbox
            checked={options.hideFev1Rejected}
            onChange={(isChecked) =>
              handleQualityFilterChangeRejected(isChecked, "fev1")
            }
            label="Rejected"
            id="fev1-rejected-checkbox"
            labelPosition="before"
            spacing="88px"
          />
          <Checkbox
            checked={options.hideFev1Usable}
            onChange={(isChecked) =>
              handleQualityFilterChangeUsable(isChecked, "fev1")
            }
            label="Usable"
            id="fev1-usable-checkbox"
            labelPosition="before"
            spacing="96px"
          />
        </RadioGroupContainer>
      </>
    );
  };

  const handleBaselineCancel = () => {
    setBaselineValue(baseline?.toFixed(1) ?? "");
    setIsBaselineOpen(false);
  };

  const handleBaselineChange = (value: string) => {
    setBaselineValue(value);
  };

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  const handleArchiveBaselineModalClose = async (confirm: boolean) => {
    if (!patientId || !measurementType || !onBaselineChange) return;

    setIsArchiveBaselineModalOpen(false);

    if (confirm) {
      const parsedPatientId = parseInt(patientId, 10);
      const notification: Notification = {
        show: true,
        message: "",
        type: "success",
        width: "max-content",
      };

      const archiveResult = await baselinesService.archiveBaseline(
        parsedPatientId,
        measurementType
      );

      if (archiveResult.status >= 200 && archiveResult.status < 300) {
        setBaselineValue("");

        notification.message = "Baseline archived successfully";
      } else {
        notification.message = "Something went wrong, please try again";
        notification.type = "error";
      }

      onBaselineChange(notification);
    }
  };

  const handleBaselineChangeModalClose = async (
    confirm: boolean,
    newBaseline?: number,
    unit?: string
  ) => {
    setIsConfirmBaselineChangeModalOpen(false);

    if (!patientId || !measurementType || !onBaselineChange || !newBaseline)
      return;

    if (confirm) {
      const parsedPatientId = parseInt(patientId, 10);
      const notification: Notification = {
        show: true,
        message: "",
        type: "success",
        width: "max-content",
      };

      const request: IAddBaseline = {
        patientId: parsedPatientId,
        measurementType,
        baseline: newBaseline,
        unit,
      };

      const addResult = await baselinesService.addBaseline(request);

      if (addResult.status >= 200 && addResult.status < 300) {
        notification.message = "Baseline added successfully";
      } else {
        notification.message = "Something went wrong, please try again";
        notification.type = "error";
      }

      onBaselineChange(notification);
    }
  };

  return (
    <OptionsBarContainer>
      <OptionsBar>
        <div
          style={{ backgroundColor: "white", borderRadius: "8px 0 0 8px" }}
          ref={calendarButtonRef}
        >
          <OptionButton
            key={`${cardTitleId}_customDate`}
            text="Custom"
            icon={<Calendar />}
            selected={selectedFilterDate.label === customDateFiterOption}
            onClick={() => {
              mixpanelActions.track(`User Action: CustomDateFilter`);
              setCalendarOpen((prevState) => !prevState);
            }}
          />
        </div>

        {dateOptions.map((dateFilterOption, index) => {
          return (
            <OptionButton
              key={`${cardTitleId}_${dateFilterOption.label}_${index}`}
              text={dateFilterOption.label}
              selected={dateFilterOption.label === selectedFilterDate.label}
              onClick={() => {
                mixpanelActions.track(
                  `User Action: Changed Date Range ${dateFilterOption.label}`
                );

                const currentDate = dayjs();
                onChange({
                  endDate: currentDate,
                  label: dateFilterOption.label,
                  startDate: dateFilterOption.value(currentDate),
                });
              }}
            />
          );
        })}
      </OptionsBar>
      <div>
        <OptionsBar borderRadius="16px">
          <OptionButton
            borderRadius="11px 0 0 11px"
            selected={!showMeasurementsTable}
            className={SvgCss()}
            text=""
            onClick={() => {
              setShowMeasurementsTable(false);
              onTableViewChange(false);
            }}
            icon={<GraphToggle />}
          />
          <OptionButton
            borderRadius="0 11px 11px 0"
            selected={showMeasurementsTable}
            className={SvgCss()}
            text=""
            onClick={() => {
              setShowMeasurementsTable(true);
              onTableViewChange(true);
            }}
            icon={<TableToggle />}
          />
        </OptionsBar>
      </div>
      <OptionsBar
        border={filterCount > 0 ? "1px solid #BDB4DD" : undefined}
        borderRadius="16px"
      >
        <div
          style={{ backgroundColor: "white", borderRadius: "16px" }}
          ref={filterButtonRef}
        >
          <OptionButton
            key={`${cardTitleId}_filter`}
            text={getFilterCountText()}
            backgroundColor={filterCount > 0 ? "#E9E6F4" : undefined}
            color={filterCount > 0 ? "#48397D" : undefined}
            borderRadius="16px"
            letterSpacing="0.1px"
            padding="2px 12px"
            hoverBackgroundColor={filterCount > 0 ? "#D0CAE7" : undefined}
            onClick={() => {
              mixpanelActions.track(`User Action: FilterButtonClicked`);
              setFilterOpen((prevState) => !prevState);
            }}
          />
        </div>
      </OptionsBar>
      <OptionsBar borderRadius="16px">
        <OptionButtonContainer ref={filterButtonRef}>
          <OptionButton
            key={`${cardTitleId}_scaleY`}
            text={initialScaleYAxis ? "Unscale Y axis" : "Scale Y axis"}
            selected={initialScaleYAxis}
            borderRadius="16px"
            letterSpacing="0.1px"
            padding="2px 12px"
            onClick={() => {
              handleScaleYAxisChange();
            }}
          />
        </OptionButtonContainer>
      </OptionsBar>

      <OptionsBar borderRadius="16px">
        <OptionButtonContainer
          ref={baselineButtonRef}
          hasBaseline={baseline !== undefined}
        >
          <OptionButton
            key={`${cardTitleId}_baseline`}
            text={
              baseline ? `Baseline: ${baseline.toFixed(1)}` : "Add baseline"
            }
            selected={isBaselineOpen}
            borderRadius="16px"
            letterSpacing="0.1px"
            padding="2px 12px"
            onClick={() => {
              mixpanelActions.track("User Action: BaselineButtonClicked");
              setIsBaselineOpen((prevState) => !prevState);
            }}
          />
        </OptionButtonContainer>
      </OptionsBar>

      {calendarOpen && (
        <DatepickerContainer ref={wrapperRef}>
          <DatePickerInput
            initialDateRange={{
              startDate: selectedFilterDate.startDate.toDate(),
              endDate: selectedFilterDate.endDate.toDate(),
            }}
            onDateChange={handleOnChangeDateWithDatePicker}
          />
        </DatepickerContainer>
      )}

      {filterOpen && (
        <FilterContainer ref={filterWrapperRef}>
          <RadioGroupContainer>
            <b>Data display</b>
            <RadioButtonContainer>
              <p>All Points</p>
              <RadioButton
                checked={checked === ""}
                onClick={() => handleClick("")}
              />
            </RadioButtonContainer>
            <RadioButtonContainer>
              <p>Highest of day</p>
              <RadioButton
                checked={checked === "BestOfDay"}
                onClick={() => handleClick("BestOfDay")}
              />
            </RadioButtonContainer>
          </RadioGroupContainer>
          <OptionDivider />
          {showHideToolbar() && (
            <RadioGroupContainer>
              <b>{measurementType.toUpperCase()} quality hide</b>
              <Checkbox
                checked={
                  measurementType === "fvc"
                    ? options.hideFvcRejected
                    : options.hideFev1Rejected
                }
                onChange={handleQualityFilterChangeRejected}
                label="Rejected"
                id={`${measurementType}-rejected-checkbox`}
                labelPosition="before"
                spacing="88px"
              />
              <Checkbox
                checked={
                  measurementType === "fvc"
                    ? options.hideFvcUsable
                    : options.hideFev1Usable
                }
                onChange={handleQualityFilterChangeUsable}
                label="Usable"
                id={`${measurementType}-usable-checkbox`}
                labelPosition="before"
                spacing="96px"
              />
            </RadioGroupContainer>
          )}
          {measurementType === "pef" && handlePEFQualityHideFilters()}
        </FilterContainer>
      )}

      {isBaselineOpen && (
        <BaselineContainer ref={baselineWrapperRef}>
          <BaselineInputContainer>
            <p>Value</p>
            {!rerender ? (
              <TextField
                label=""
                placeholder="0"
                backgroudColor="white "
                value={baselineValue}
                className={BaselineInputCss()}
                onChange={(event) => handleBaselineChange(event.target.value)}
                type="number"
              />
            ) : null}

            {baseline ? (
              <ClearButton
                onClick={() => {
                  setIsArchiveBaselineModalOpen(true);
                }}
              >
                <CircleX />
              </ClearButton>
            ) : null}
          </BaselineInputContainer>

          <SaveContainer>
            <BaselineButton onClick={handleBaselineCancel}>
              CANCEL
            </BaselineButton>
            <BaselineButton
              isSave
              isDisabled={baselineValue === ""}
              onClick={() => {
                if (baselineValue !== "") {
                  setIsConfirmBaselineChangeModalOpen(true);
                }
              }}
            >
              SAVE
            </BaselineButton>
          </SaveContainer>
        </BaselineContainer>
      )}

      {isConfirmBaselineChangeModalOpen ? (
        <ConfirmBaselineChangeModal
          oldBaseline={baseline}
          newBaseline={parseFloat(baselineValue)}
          unit={unit}
          onClose={handleBaselineChangeModalClose}
        />
      ) : null}

      {isArchiveBaselineModalOpen && baseline ? (
        <ArchiveBaselineModal
          baseline={baseline}
          unit={unit}
          onClose={handleArchiveBaselineModalClose}
        />
      ) : null}
    </OptionsBarContainer>
  );
}
