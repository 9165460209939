import { AxiosResponse } from "axios";

import {
  IAddWorflowResponse,
  IWorkflow,
  IWorkflowSettings,
  IWorkflowsSelectOption,
  IWorkflowsSurveyOption,
  IWorkflowsTableContentServerResponse,
  IWorkflowVersion,
} from "../@types/Worflows";
import { api } from "./api";

export const workflowsService = {
  async getAllWorflows(
    hospitalGroupId?: number
  ): Promise<AxiosResponse<IWorkflowsTableContentServerResponse>> {
    const url = "/Portal/Workflows";

    const serverResponse = await api.get(
      url,
      hospitalGroupId ? { params: { hospitalGroupId } } : undefined
    );

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getWorflowsRuleTypes(): Promise<
    AxiosResponse<IWorkflowsSelectOption[]>
  > {
    const url = "/Portal/Workflows/GetRuleTypes";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getWorflowsRuleConditionComparators(): Promise<
    AxiosResponse<IWorkflowsSelectOption[]>
  > {
    const url = "/Portal/Workflows/GetRuleConditionComparators";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getWorflowsRuleConditionTriggers(): Promise<
    AxiosResponse<IWorkflowsSelectOption[]>
  > {
    const url = "/Portal/Workflows/GetRuleConditionTriggers";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getWorflowsResponseTypes(): Promise<
    AxiosResponse<IWorkflowsSelectOption[]>
  > {
    const url = "/Portal/Workflows/GetResponseTypes";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getWorflowsResponseTimes(): Promise<
    AxiosResponse<IWorkflowsSelectOption[]>
  > {
    const url = "/Portal/Workflows/GetResponseTimes";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getWorflowsActionTypes(): Promise<
    AxiosResponse<IWorkflowsSelectOption[]>
  > {
    const url = "/Portal/Workflows/GetActionTypes";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getWorflowsSurveyOptions(
    wardIds?: number[]
  ): Promise<AxiosResponse<IWorkflowsSurveyOption[]>> {
    const url = "/Portal/Workflows/GetSurveyOptions";

    const serverResponse = await api.post(url, {
      wards: wardIds,
    });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async addWorkflow(
    workflow: IWorkflow
  ): Promise<AxiosResponse<IAddWorflowResponse>> {
    const url = "/Portal/Workflows";

    const serverResponse = await api.post(url, workflow);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getWorkflowById(workflowId: string): Promise<AxiosResponse<IWorkflow>> {
    const url = `/Portal/Workflows/${workflowId}`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateWorkflow(
    workflowId: string,
    workflowSettings: IWorkflowSettings
  ): Promise<AxiosResponse<IWorkflow>> {
    const url = `/Portal/Workflows/${workflowId}`;

    const serverResponse = await api.put(url, workflowSettings);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async addWorkflowVersion(
    workflowId: string,
    workflowVersion: IWorkflowVersion
  ): Promise<AxiosResponse<IWorkflow>> {
    const url = `/Portal/Workflows/${workflowId}/Versions`;

    const serverResponse = await api.post(url, workflowVersion);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateWorkflowStatus(
    workflowId: string,
    published: boolean
  ): Promise<AxiosResponse<IWorkflow>> {
    const url = `/Portal/Workflows/${workflowId}/UpdateStatus`;

    const serverResponse = await api.post(url, null, {
      params: {
        published,
      },
    });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
